/*App.js*/

import React, { useState, useEffect, useMemo } from 'react';
import { Image } from 'react-bootstrap';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { setCookie, getCookie } from './utils'; // Import the functions

const login_api_url = process.env.REACT_APP_LOGIN_API_URL;
const resource_api_url = process.env.REACT_APP_RESOURCE_API_URL;


function App() {
    const [showGoogleLogin, setShowGoogleLogin] = useState(true);
    const [user, setUser] = useState(null);
    const [strava_data, setStrava_data] = useState(null);
    const [listItems, setListItems] = useState(null);
    const [responseData, setResponseData] = useState({});
    const [loadingstatus, setLoadingstatus] = useState({});
    const [menu, setMenu] = useState(null);
    const [status, setStatus] = useState(null);
    const [showConnectStravaButton, setShowConnectStravaButton] = useState(false);

    function onClickHandler(){
        
        setMenu(<div>After logging into google, please wait a few seconds for this page to load <br /><br/>{googleLoginButton}</div>);
      }

    const googleLoginButton = useMemo(() => {

        return (
            <GoogleLogin
                shape='pill'
                click_listener={() => onClickHandler()}
                onSuccess={credentialResponse => {
                    axios.post(`${login_api_url}/api/Login`, {
                        test: 'test'
                    }, {
                        headers: {
                            Authorization: credentialResponse.credential,
                        }
                    })
                        .then((res) => {
                            setCookie('stratava_token', res.data.token, { path: '/', expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365) });
                            setShowGoogleLogin(false);
                            checkToken();
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
                
            />
        );
    }, []);

    const stravaConectButton = useMemo(() => {
        return (
            <button onClick={() => {
                const clientId = '13077';
                const redirectUri = `${resource_api_url}/api/Store_Tokens`;
                const scope = 'read,activity:read_all';
                const state = getCookie('stratava_token');
                const authUrl = `https://www.strava.com/oauth/authorize?state=${state}&client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&approval_prompt=force&scope=${scope}`;
                window.open(authUrl, '_self');
            }}>
                Open Strava Login
            </button>
        );
    }, []);

    function checkToken() {
        const cookieValue = getCookie('stratava_token');
        if (cookieValue) {
            axios.get(`${login_api_url}/api/CheckToken`, {
                headers: {
                    Authorization: cookieValue,
                }
            })
                .then(response => {
                    if (response.data.userid) {
                        setUser(response.data.userid);
                        setStatus(response.status);
                    }
                })
                .catch(error => {
                    setStatus(error.response.status);
                });
        } else {
            setStatus(401);
            setShowGoogleLogin(true);
        }
    }

    useEffect(() => {
        switch (status) {
            case 401:
                setMenu(<div>Welcome, this is a first-time visit. Please log in using Google <br />{googleLoginButton}</div>);
                break;
            case 202:
                setMenu(<div>Logged in / Authenticated, checking if we have your Strava tokens.</div>);
                axios.get(`${resource_api_url}/api/User`, {
                    headers: {
                        Authorization: getCookie('stratava_token'),
                    }
                })
                    .then((res) => {
                        setStatus(res.status);
                    })
                    .catch((err) => {
                        setStatus(err.response.status);
                    });
                break;
            case 204:
                setMenu(<div>We have your Strava tokens to log in!</div>);
                axios.get(`${resource_api_url}/api/Get_Activities`, {
                    headers: {
                        Authorization: getCookie('stratava_token'),
                    }
                })
                    .then((res) => {
                        if (res.status === 200) {
                            setStrava_data(res.data);
                        } else {
                            setStatus(res.status);
                        }
                    })
                    .catch((err) => {
                        setStatus(err.response.status);
                    });
                break;
            case 404:
                setMenu(<div>Please connect to Strava <br />{stravaConectButton}</div>);
                break;
            case 440:
                setMenu(<div>Session Expired, please log in again <br /><br/>{googleLoginButton}</div>);
                setShowGoogleLogin(true);
                break;
            default:
                console.log('Unknown status');
                checkToken();
                break;
        }
    }, [status, googleLoginButton]);

    const updateStatus = (entryId, status) => {
        return new Promise((resolve) => {
            setLoadingstatus(prevStatus => {
                const newStatus = { ...prevStatus, [entryId]: status };
                resolve(newStatus);
                return newStatus;
            });
        });
    };

    const handleButtonClick = (entry) => {
        // First update the status
        setLoadingstatus(prevStatus => ({
            ...prevStatus,
            [entry.id]: 'Getting data...'
        }));

        // Ensure the UI updates before making the API call
        setTimeout(() => {
            axios.post(`${resource_api_url}/api/Get_Activity`, {
                activity: entry
            }, {
                headers: {
                    Authorization: getCookie('stratava_token'),
                }
            })
                .then((res) => {
                    setLoadingstatus(prevStatus => ({
                        ...prevStatus,
                        [entry.id]: 'Here is your data'
                    }));
                    setResponseData(prevData => ({
                        ...prevData,
                        [entry.id]: res.data
                    }));
                })
                .catch((err) => {
                    if (err.response.status === 401) {
                        setShowGoogleLogin(true);
                    } else if (err.response.status === 404) {
                        setShowConnectStravaButton(true);
                    }
                });
        }, 100); // Adding a small delay to ensure UI update
    };

    useEffect(() => {
        if (strava_data) {
            const listItems = strava_data.map(entry => (
                entry.distance > 0 && (
                    <div key={entry.id}>
                        <br />
                        <li>{entry.start_date.split('T')[0].split('-').reverse().join('-')} - {entry.start_date.split('T')[1].split(':')[0] > 12 ? entry.start_date.split('T')[1].split(':')[0] - 12 : entry.start_date.split('T')[1].split(':')[0]}:{entry.start_date.split('T')[1].split(':')[1]}
                            {entry.start_date.split('T')[1].split(':')[0] > 12 ? 'PM' : 'AM'} <br /> {entry.name} <br /> {Math.round(entry.elapsed_time / 60)} Minutes
                        </li>
                        <button onClick={() => handleButtonClick(entry)}>Get Activity Stats</button>
                        <p>{loadingstatus[entry.id]}</p>
                        {responseData[entry.id] && (
                            <ul>
                                {Object.entries(responseData[entry.id]).map(([key, value], index) => (
                                    <li key={index}><strong>{key}:</strong> {value}</li>
                                ))}
                            </ul>
                        )}
                    </div>
                )
            ));
            setListItems(listItems);
        }
    }, [strava_data, responseData, loadingstatus]);

    return (
        <div>
             <Image className="logo-image" src={`${process.env.PUBLIC_URL}/images/Transparent Logo.png`} alt="Strava Logo" />
    
            {!showGoogleLogin && <p>{user}</p>}
            <br />
            {menu}
            {listItems && (
                <ul>
                    {listItems}
                </ul>
            )}
        </div>
    );
}

export default App;
